* { box-sizing: border-box; }

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,sans-serif;
  line-height: 1.5;
  margin: 0;
  color: #111;
  background-color: #fff;
  background: #efefef;
}

a, a:visited, .link {
  color: #0074D9;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

a.btn-primary {
  color: #fff;
}

.cursor-pointer {
  cursor: pointer;
}

.btn-secondary {
  color: #fff;
  background-color: #aaa;
  border-radius: 3px;
}

a.btn-secondary {
  color: #fff;
}

.last-child-border-bottom-none:last-child {
  border-bottom: 0;
}

.bg-light-gray {
  background: #ddd;
}

.dark-gray {
  color: #666;
}

.AppHeader {
  background: #ddd;
  padding: 0.5rem 1rem;
}

.AppHeader h1 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
}

.AppHeader ul {
  line-height: 1.25;
  /* marg */
}

.hide-board-wrapper {
  max-height: 0px;
  overflow-y: hidden;
}

.HoldItem {
  user-select: none;
  border: 1px solid #ccc;
}

.HoldItem--editing {
  background: #ddd;
}

.FilterInput {
  position: relative;
}

.FilterInput input {
  padding-right: 4rem;
}

.FilterInput button {
  position: absolute;
  top: 0.375rem;
  right: 0.375rem;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.btn-small {
  font-size: .75rem;
  padding: 0.25rem 0.5rem;
}

.ProblemFilters-Filter {
  padding-bottom: 1rem;
}

.ProblemFilters-Filter select {
  margin-bottom: 0;
}

.ProblemFilters-Actions {
  background: #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

.ServiceWorkerUpdateAlert {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.25rem;
  background: #aaa;
  border: 1px solid #666;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.tag {
  display: inline-block;
  background: #ccc;
  padding: 0 0.25rem;
  border-radius: 0.125rem;
  font-size: 0.75rem;
}

@media (min-width: 64em) {
  .hide-board-wrapper {
    max-height: none;
    overflow-y: visible;
  }

  .AppHeader {
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .AppHeader h1 {
    font-size: var(--h1);
  }

  .lg-h1 {
    font-size: var(--h1);
  }

  .lg-pl3 {
    padding-left: var(--space-3);
  }

  .lg-pr0 {
    padding-right: 0;
  }

  .lg-pr3 {
    padding-right: var(--space-3);
  }

  .lg-px3 {
    padding-left: var(--space-3);
    padding-right: var(--space-3);
  }

  .lg-mb0 {
    margin-bottom: 0;
  }

  .lg-my2 {
    margin-top: var(--space-2);
    margin-bottom: var(--space-2);
  }

  .lg-inline {
    display: inline;
  }
}
